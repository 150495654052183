<template>
  <div app-header>
    <header :class="{on:active}">
      <div class="h-inner">
        <RouterLink to="/" class="logo">HCORE</RouterLink>
        <nav class="web" @mouseover="siteMapOpen">
          <a href="/brand">BRAND</a>
          <a href="/story">STORY</a>
          <a href="/product">PRODUCT</a>
          <a href="/support">SUPPORT</a>
        </nav>
        <a class="menu mo" @click="siteMapOpen">메뉴열기</a>
        <transition name="fade">
          <div class="site-map" v-if="siteMap" @mouseleave="siteMapClose">
            <div class="dim"></div>
            <RouterLink to="/" class="logo web">HCORE</RouterLink>
            <div class="map-list">
              <ul>
                <li>
                  <RouterLink class="link" to="/brand">BRAND</RouterLink>
                  <div class="menu-btn">
                    <RouterLink to="/brand/about">About H CORE</RouterLink>
                    <RouterLink to="/brand/history">H CORE History</RouterLink>
                  </div>
                </li>
                <li>
                  <RouterLink class="link" to="/story">STORY</RouterLink>
                  <div class="menu-btn">
                    <RouterLink to="/story/adv">H CORE AD</RouterLink>
                    <RouterLink to="/story/news">H CORE News</RouterLink>
                  </div>
                </li>
                <li>
                  <RouterLink class="link" to="/product">PRODUCT</RouterLink>
                  <div class="menu-btn">
                    <RouterLink to="/product">All Product</RouterLink>
                    <RouterLink to="/section">Section</RouterLink>
                    <RouterLink to="/bar">Reinforcing Bar</RouterLink>
                    <RouterLink to="/plate">Plate</RouterLink>
                    <RouterLink to="/pipe">Steel Pipe</RouterLink>
                    <RouterLink to="/hrcr">HR/CR</RouterLink>
                  </div>
                </li>
                <li>
                  <RouterLink class="link" to="/support">SUPPORT</RouterLink>
                  <div class="menu-btn">
<!--                    <RouterLink to="/support/engineer">Engineering Service</RouterLink>-->
<!--                    <RouterLink to="/support/tps">Total Package Service</RouterLink>-->
                    <RouterLink to="/support/qna">Q&A</RouterLink>
                    <RouterLink to="/support/reference">Reference Data</RouterLink>
                  </div>
                </li>
              </ul>
              <p class="txt web">현대제철이 만든 기본을 지키는프리미엄 강재<br> <b>안</b>전을 <b>심</b>은 철, <b>안심 H CORE</b></p>
            </div>
            <a class="close mo" @click="siteMapClose">닫기</a>
          </div>
        </transition>
      </div>
    </header>
  </div>
</template>

<script>

export default {
  name: 'AppHeader',
  data() {
    return {
      siteMap: false,
      active: false,
      winWidth: 0,
      nav: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  watch: {
    $route() { this.siteMap = false; this.active = false; }
  },
  methods: {
    handleResize() {
      this.winWidth = window.innerWidth;
    },
    siteMapOpen() {
      this.siteMap = true;
      this.active = true;
    },
    siteMapClose() {
      this.siteMap = false;
      this.active = false;
    },
  }
};
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[app-header] { .fs(0); .wf; .abs; .lt;
  header { .rel; .h(132); .bgc(#fff); z-index: 20;
    .h-inner {
      .logo { .wh(209,132); .contain('/images/mo/logo.png'); .ib; }
      .menu { .wh(120,132); .contain('/images/mo/menu.png'); .ib; .fr; }
      .site-map { .fix; .f; .lt; .pt(135); .-box; z-index: 100; overflow: auto;
        .dim { .abs; .lt; .f; .bgc(#0c0d0d); .o(0.98); }
        .map-list { .p(0,70,50); .-box; .abs; .lt(0,135); .wf; .mt(130); .bgc(#0c0d0d);
          ul li { .tl; .p(37,0); .-box; .-b(#333,3);
            &:last-child { .-b; }
            .link { .fs(48); .lh(60); .w(46%); .ib; .vat; color:#fff; .robo; .bold; .ls(0.02em); }
            .menu-btn { .w(54%); .ib; .vat;
              a { .fs(36); .lh(60); .ls(-0.025em); .spoqa; color:#58a4de; .block; .pointer; }
            }
          }
        }
        .close { .wh(51,52); .abs; .lt(50%,135); transform: translateX(-50%); .contain('/images/mo/pop-x.png'); }
      }
    }
  }
}
@media screen and(min-width:1024px) {
  [app-header] { .rel;
    header { .h(120); .p(0); .p(0,50); .-box;
      .h-inner { .max-w(1720); .mh-c; .rel;
        .logo { .wh(144,120); .contain('/images/pc/logo-blue.png'); }
        .menu { .abs; .lt(10000,10000); .hide; }
        nav { .abs; .rt; z-index: 1;
          a { .fs(20); .lh(120); .robo; .bold; .ls(0.02em); color:#0079ae; .ib; .vam; .tl; .w(150); }
        }
        .site-map { .abs; .lt; .pt(0); .h(350); overflow: hidden;
          .logo { .wh(144,120); .contain('/images/pc/logo-blue.png'); .abs; .lt; .pointer; z-index: 5; }
          &:before { .cnt; .wh(1720,1); .bgc(#cccccc); .abs; .lt(50%,120); transform: translateX(-50%); z-index: 1; }
          .dim { .hide; }
          .map-list { .p(0); .rel; .lt; .f; .max-w(1720); .mh-c; .mt(0); .tr; background: none;
            ul { .ib;
              li { .tc; .p(0); .-b; .ib; .vat; .tl; .w(150);
                .link { .fs(20); .lh(120); width:auto; color:#0079ae; .block; }
                .menu-btn { width:auto; .mt(20);
                  a { .fs(16); .lh(30); color:#0a243c; .regular; transition: color 0.2s;
                    &:hover { color:#0079ae; }
                  }
                }
              }
            }
            .txt { .fs(24); .lh(36); .tl; .light; .ggo; .abs; .lb(0,50); color:#ccc;
              b { .bold; }
            }
          }
          .close { .hide; }
        }
      }
      &.on {
        &:after { .cnt; .wh(100vw,350); .bgc(#fff); .abs; .lt(50%,0); transform: translateX(-50%); z-index: -1; }
        .h-inner {
          .menu { .wh(70,120); .contain('/images/pc/menu.png'); .ib; .fr; }
        }
      }
    }
  }
}
</style>
