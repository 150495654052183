import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

const page = path => () => import(`@/views/pages${path}.vue`);

Vue.use(VueRouter)
Vue.use(Meta);

const routes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/main',
    component: page('/Main'),
  },
  {
    path: '/brand',
    redirect: '/brand/about',
  },
  {
    path: '/brand/:brand(about|history)',
    component: page('/Brand'),
  },
  {
    path: '/story',
    redirect: '/story/adv',
  },
  {
    path: '/story/:story(adv|news)',
    component: page('/Story'),
  },
  {
    path: '/support',
    redirect: '/support/qna',
  },
  {
    path: '/support/:support(qna|reference)',
    component: page('/Support'),
  },
  {
    path: '/product',
    component: page('/AllProduct'),
  },
  {
    path: '/section',
    component: page('/section/Section'),
  },
  {
    path: '/shp',
    component: page('/section/Shp'),
  },
  {
    path: '/shn460',
    component: page('/section/Shn460'),
  },
  {
    path: '/chb',
    component: page('/section/Chb'),
  },
  {
    path: '/sheet',
    component: page('/section/Sheet'),
  },
  {
    path: '/shn',
    component: page('/section/Shn'),
  },
  {
    path: '/offshore',
    component: page('/section/Offshore'),
  },
  {
    path: '/gr',
    component: page('/section/Gr'),
  },
  {
    path: '/bar',
    component: page('/bar/Bar'),
  },
  {
    path: '/low',
    component: page('/bar/Low'),
  },
  {
    path: '/strength',
    component: page('/bar/Strength'),
  },
  {
    path: '/barGr',
    component: page('/bar/BarGr'),
  },
  {
    path: '/plate',
    component: page('/plate/Plate'),
  },
  {
    path: '/hs',
    component: page('/plate/Hs'),
  },
  {
    path: '/sn',
    component: page('/plate/Sn'),
  },
  {
    path: '/pipe',
    component: page('/pipe/Pipe'),
  },
  {
    path: '/stp',
    component: page('/pipe/Stp'),
  },
  {
    path: '/snt',
    component: page('/pipe/Snt'),
  },
  {
    path: '/hrcr',
    component: page('/hrcr/Hrcr'),
  },
  {
    path: '/coil',
    component: page('/hrcr/Coil'),
  },
  {
    path: '/eg',
    component: page('/hrcr/Eg'),
  },
]

export default () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => null,
  base: process.env.BASE_URL,
  routes
})
