export default {
  business: [
    { nm: '방문예약 시스템', link: 'https://visit.hyundai-steel.com/reserve/index.do' },
    { nm: '고객포탈 시스템', link: 'http://cp.hyundai-steel.com/' },
    { nm: '파트너 시스템', link: 'http://sm.hyundai-steel.com/cs/cm/partner_login.jsp' },
    { nm: '통합 구매자재 시스템', link: 'https://hpro.hyundai-steel.com/spmainpage.do' },
  ],
  family: [
    { type: 'tit', nm: '완성차', link: '' },
    { type: 'link', nm: '현대자동차', link: 'https://www.hyundai.com/kr/ko/e'},
    { type: 'link', nm: '기아자동차', link: 'https://www.kia.com/kr/main.html'},
    { type: 'tit', nm: '철강', link: ''},
    { type: 'link', nm: '현대비엔지스틸', link: 'https://www.bngsteel.com/kr/'},
    { type: 'link', nm: '현대종합특수강', link: 'http://www.hyundai-specialsteel.com/'},
    { type: 'tit', nm: '건설', link: ''},
    { type: 'link', nm: '현대건설', link: 'https://www.hdec.kr/intro.aspx'},
    { type: 'link', nm: '현대엔지니어링', link: 'https://www.hec.co.kr/ko'},
    { type: 'link', nm: '현대스틸산업', link: 'http://www.hdesi.co.kr/kr/Default.aspx'},
    { type: 'link', nm: '현대도시개발', link: 'https://www.latierra.kr/'},
    { type: 'link', nm: '현대종합설계', link: 'https://www.hda.co.kr/ko/main/'},
    { type: 'tit', nm: '부품', link: ''},
    { type: 'link', nm: '현대모비스', link: 'https://www.mobis.co.kr/kr/index.do'},
    { type: 'link', nm: '현대위아', link: 'https://www.hyundai-wia.com/main/main.asp'},
    { type: 'link', nm: '현대트랜시스', link: 'https://www.hyundai-transys.com/ko/main.do'},
    { type: 'link', nm: '현대케피코', link: 'https://www.hyundai-kefico.com/ko/main/index.do'},
    { type: 'link', nm: '현대다이모스', link: 'http://www.hyundai-dymos.com/'},
    { type: 'link', nm: '현대엠시트', link: 'http://www.hyundai-mseat.com/ko/main.asp'},
    { type: 'link', nm: '현대오트론', link: 'http://www.hyundai-autron.com/'},
    { type: 'link', nm: '현대아이에이치엘', link: 'http://www.ihl.co.kr/'},
    { type: 'link', nm: '현대엠엔소프트', link: 'http://www.hyundai-mnsoft.com/'},
    { type: 'link', nm: '현대파텍스', link: 'http://www.partecs.co.kr/Main.'},
    { type: 'tit', nm: '금융', link: ''},
    { type: 'link', nm: '현대캐피탈', link: 'https://www.hyundaicapital.com/main/main/CPMNMN0101.hc'},
    { type: 'link', nm: '현대카드', link: 'https://www.hyundaicard.com/index.jsp'},
    { type: 'link', nm: '현대차증권', link: 'https://www.hmsec.com/'},
    { type: 'link', nm: '현대커머셜', link: 'https://www.hyundaicommercial.com/home.hc'},
    { type: 'tit', nm: '기타', link: ''},
    { type: 'link', nm: '현대글로비스', link: 'http://www.glovis.net/Kor/'},
    { type: 'link', nm: '현대로템', link: 'https://www.hyundai-rotem.co.kr/'},
    { type: 'link', nm: '이노션', link: 'http://www.innocean.com/ww-en/'},
    { type: 'link', nm: '현대오토에버', link: 'https://www.hyundai-autoever.com/kor/main/index.do'},
    { type: 'link', nm: '현대서산농장', link: 'https://www.hdfnd.co.kr/'},
    { type: 'link', nm: '현대엔지비', link: 'http://www.hyundai-ngv.com/main/index.do'},
    { type: 'link', nm: '해비치호텔&리조트', link: 'https://www.haevichi.com/'},
    { type: 'tit', nm: '관련 학·협회', link: ''},
    { type: 'link', nm: '한국철강협회', link: 'https://www.kosa.or.kr/'},
    { type: 'link', nm: '한국강구조학회', link: 'https://www.kssc.or.kr/home/'},
    { type: 'link', nm: '한국도로협회', link: 'http://www.kroad.or.kr/'},
    { type: 'link', nm: '학국지반공학회', link: 'https://www.kgshome.org/'},
    { type: 'link', nm: '한국건축구조기술사회', link: 'http://www.ksea.or.kr/'},
    { type: 'link', nm: '한국콘크리트학과', link: 'https://www.kci.or.kr/'},
    { type: 'link', nm: '한국자원순환산업진흥협회', link: 'http://www.ripa.or.kr/'},
    { type: 'link', nm: '한국교량및구조공학회', link: 'https://www.kibse.or.kr'},
    { type: 'link', nm: '대한토목학회', link: 'https://www.ksce.or.kr'},
    { type: 'link', nm: '한국건축가협회', link: 'https://www.kia.or.kr:8446/main.asp'},
    { type: 'link', nm: '건축자재정보센터', link: 'http://kiramat.kira.or.kr/'},
  ]
}
