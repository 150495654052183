<template>
  <div id="app" :class="[lang, touch ? 'touch' : 'no-touch', fetching, home]">
    <AppHeader />
    <scroll-holder container-class="scroll-container" body-class="scroll-body">
      <div content-holder>
        <router-view/>
      </div>
      <AppFooter />
    </scroll-holder>
  </div>
</template>

<script>
import AppHeader from "@/views/common/AppHeader";
import AppFooter from "@/views/common/AppFooter";

export default {
  name: 'App',
  components: {AppFooter, AppHeader },

  data() {
    return {
    };
  },
  metaInfo() {
    return {
      title: this.$t('meta.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.title'), },
        { vmid: 'description', content: this.$t('meta.description'), },
        { vmid: 'keywords', content: this.$t('meta.keywords'), },
        { vmid: 'image', content: '/images/pc/main-vk1.jpg', }
      ],
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.unLoadEvent);
  },
  computed: {
    touch() { return this.$store.state.browser.touch; },
    fetching() { return this.$store.state.fetchingAsyncData && 'fetching'; },
    home() { return this.$route.path === '/'+this.lang+'/' && 'home'; },
  },
  methods: {
    unLoadEvent(e) {
      e.preventDefault();
      document.documentElement.scrollTo(0,0);
    },
  },
}
</script>

<style lang="less">
@import "~@/less/common";
html { scroll-snap-type: y proximity; overflow-y: scroll; }
[content-holder] {
  min-height: calc(100vh - 17.75rem);
}
.mo { .ib; }
.web { .hide; }
[v-clock] {
  display: none;
}
@media screen and(min-width:768px) {
  html { font-size: 18px; }
}
@media screen and(min-width:1024px) {
  #app { .crop; }
  html { font-size: 22px; }
  .scroll-container { .mt(-120); }
  [content-holder] {
    min-height: calc(100vh - 195px);
  }
  .mo { .hide; }
  .web { .ib; }
}
@media screen and(min-width:1400px) {
  html { font-size: 26px; }
}
@media screen and(min-width:1920px) {
  html { font-size: 32px; }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
